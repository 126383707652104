import default_ from "./default.json";
import advancedRuleTexts from "./advancedRuleTexts.json";
import deviceCategories from "./deviceCategories.json";
import deviceTypes from "./deviceTypes.json";
import historyMessages from "./historyMessages.json";
import latestNewsPages from "./latestNewsPages.json";
import pairingCategories from "./pairingCategories.json";
import pairingDeviceCategories from "./pairingDeviceCategories.json";
import pairingDevices from "./pairingDevices.json";
import preLoginPages from "./preLoginPages.json";
import roomTemplateLabels from "./roomTemplateLabels.json";
import ruleCategories from "./ruleCategories.json";
import services from "./services.json";
import smartWidgetsCategories from "./smartWidgetsCategories.json";
import templateRuleTexts from "./templateRuleTexts.json";
import zWaveNotifications from "./zWaveNotifications.json";

export default {
	...default_,
	advancedRuleTexts,
	deviceCategories,
	deviceTypes,
	historyMessages,
	latestNewsPages,
	pairingCategories,
	pairingDeviceCategories,
	pairingDevices,
	preLoginPages,
	roomTemplateLabels,
	ruleCategories,
	services,
	smartWidgetsCategories,
	templateRuleTexts,
	zWaveNotifications,
} as const;
